/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const React = require('react')
require('./src/style/global.css')
const LoadFont = require('boostly-ui2').LoadFont

export const wrapPageElement = ({ element }) => {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <LoadFont />
      {element}
    </div>
  )
}
